import users from '../users'   
import DATA from '../../plugins/DATA' 
import DB from '../../plugins/DB'   
import  { 
  toDateTime, 
  toDate,
  toTime,
  TEXT_UP,
  TEXT_UP1,
  TEXT_UP11,
  TEXT_UP0,
  TEXT_EQ,
  TEXT_SPLIT_N,
  TEXT_SPACE0, 
  DEFINED_ONLY
} from '../../plugins/MBS_actions' 
import formatNumber  from '../../plugins/formatNumber'   
import moment from 'moment'      
const toNumber=formatNumber.toNumber
const toMoney=formatNumber.toMoney
const toSameLength=formatNumber.toSameLength
  
let NAME = "STORE-LOCALS"

export default{
    state: {   
      LOCAL_DAYS_SHIFTS:null, 
      LOCAL_CASHUPS:null, 
      LOCAL_SALE_ORDERS:null, 
      LOCAL_ITEM_ORDERS:null, 
      
      DAYS_SHIFTS:null, 
      CASHUPS:null,  
      SALE_ORDERS:null,  
      ITEM_ORDERS:null,

      CUSTOMERS:null,   
      ITEMS:null,    
      ONLINE_LAN_STATUS:false,    
       
    },
    //___________________-GETTERS-_________________
    getters: {  
      get(state) { 
        return state
      },
      getOnlineLanStatus(state) { 
        return state.ONLINE_LAN_STATUS
      },  

    },
    //___________________-MUTATIONS-___________________
    mutations:{  
      setLocals(state, payload) {
        try {
          let name = payload?payload.name:null
          let data = payload?payload.data:null 
          state[name]=data  
        } catch (error) {
          console.log(error,'error.....');
        }
      }, 
    },
    //____________________[ACTIONS]_____________________
    actions: {    
      lan_status({ commit,state}, payload) {
        try { 
          commit("setLocals",{name:"ONLINE_LAN_STATUS",data:payload})
   
        } catch (error) {
          commit("ERROR",{
            error:{
              error:error,
              data:payload
            },
            from:'lan_status',
            page:NAME, 
          })
        }
      },
      lan_update({ commit,state}, payload) {
        try { 
          console.log(payload,'payload........+++'); 
          
        } catch (error) {
          commit("ERROR",{
            error:{
              error:error,
              data:payload
            },
            from:'lan_status',
            page:NAME, 
          })
        }
      },  
    },
}