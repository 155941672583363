import Vue from 'vue';  
import { store } from '../store';
import moment from 'moment';
import { toNumber } from './formatNumber';
import DATA from './DATA';
let NAME = "ACTION-BUS"

export const EventBus = new Vue({
    data(){
        return{
            INPUT_TEXT : '',
            INPUT_SPACIAL_KEYS : [],
            INPUT_TIMER : true,
            INPUT_NEW_TEXT : true,
            QR_SCAN_STARTED : false,
            NFC_EVENT : null,
            NFC_STATUS : null,
            NFC_LIVE : false,
            
            LOCAL_DB_EVENT : null,
            LOCAL_DB_STATUS : null,
            LOCAL_DB_LIVE : false,

        }
    },
    created(){ 
        this.ON_KEY_DOWN()
        this.ON_CLICK()
        this.ON_RIGHT_CLICK()
        this.ON_DOUBLE_CLICK()
        this.ON_MOUSEOVER()
        // this.START_NFC()
    },
    computed:{
        NfcEvent(){
            return this.NFC_EVENT
        },
        NfcStatus(){
            return this.NFC_STATUS
        },
        NfcLive(){
            return this.NFC_LIVE
        },
        LocalServerEvent(){
            return this.LOCAL_DB_EVENT
        },
        LocalServerStatus(){
            return this.LOCAL_DB_STATUS
        },
        LocalServerLive(){
            return this.LOCAL_DB_LIVE
        },
    }, 
    methods:{
        error(error){
            if(!error){return}
            let err = error.error?error.error:'...'
            let from = error.from?error.from:'...'
            let page = error.page?error.page:'...'
            store.dispatch('ERROR',{
                error:err,
                from:from,
                page:page
            })
        },
        ON_KEY_DOWN(){ 
            try {  
                if (!this.QR_SCAN_STARTED) { 
                    this.QR_SCAN_STARTED = true
                    // document.addEventListener("keydown", function(e) {
                    window.addEventListener("keydown", function(e) {
                        let textInput = e.key  
                        const timeStamp = e.timeStamp; 
                        
                        EventBus.$emit('ON_KEY_DOWN',textInput,timeStamp)
                        if (this.INPUT_TEXT == undefined||this.INPUT_TEXT == null||this.INPUT_TEXT == 'undefined') {
                            this.INPUT_TEXT = '' 
                        }if (this.INPUT_SPACIAL_KEYS == undefined||this.INPUT_SPACIAL_KEYS == null||this.INPUT_SPACIAL_KEYS == 'undefined') {
                            this.INPUT_SPACIAL_KEYS = []
                        }
                         


                        if (!this.INPUT_TIMER) {
                            this.INPUT_TIMER = moment().valueOf() 
                        } else {
                            let dif =toNumber(moment().valueOf())-toNumber(this.INPUT_TIMER) 
                            if (dif>100) {
                                this.INPUT_TEXT=''
                                this.INPUT_SPACIAL_KEYS=[]
                            } 
                            this.INPUT_TIMER = moment().valueOf() 
                        }
 
                        if (textInput==='Enter') { 
                            this.INPUT_SPACIAL_KEYS.push(textInput)
                            EventBus.$emit('ON_QR_SCAN',this.INPUT_TEXT,this.INPUT_SPACIAL_KEYS,timeStamp) 
                            this.INPUT_TEXT=''
                            this.INPUT_SPACIAL_KEYS=[]
                            this.INPUT_NEW_TEXT = true 
                        }else if (textInput==='Shift') {
                            this.INPUT_SPACIAL_KEYS.push(textInput)   
                        }else if (textInput==='NumLock') {
                            this.INPUT_SPACIAL_KEYS.push(textInput) 
                        }else if (textInput==='CapsLock') {
                            this.INPUT_SPACIAL_KEYS.push(textInput) 
                        }else{ 
                            this.INPUT_TEXT = this.INPUT_TEXT+textInput
                            this.INPUT_NEW_TEXT = false
                        } 
                        EventBus.$emit('ON_TYPING',this.textInput,timeStamp) 
                    })
                } 
            } catch (error) {
                reject(error)
                this.error({
                    error:error,
                    from:'ON_KEY_DOWN',
                    page:NAME, 
                })  
            } 
        },
        ON_CLICK(){ 
            try {   
                window.addEventListener("click", function(e) { 
                    const timeStamp = e.timeStamp;  
                    EventBus.$emit('ON_CLICK',e,timeStamp) 
                })
            } catch (error) {
                reject(error)
                this.error({
                    error:error,
                    from:'ON_CLICK',
                    page:NAME, 
                })  
            } 
        },
        ON_RIGHT_CLICK(){ 
            try {   
                window.addEventListener("contextmenu ", function(e) { 
                    const timeStamp = e.timeStamp;  
                    EventBus.$emit('ON_RIGHT_CLICK',e,timeStamp) 
                })
            } catch (error) {
                reject(error)
                this.error({
                    error:error,
                    from:'ON_RIGHT_CLICK',
                    page:NAME, 
                })  
            } 
        },
        ON_DOUBLE_CLICK(){ 
            try {   
                window.addEventListener("dblclick ", function(e) { 
                    const timeStamp = e.timeStamp;  
                    EventBus.$emit('ON_DOUBLE_CLICK',e,timeStamp) 
                })
            } catch (error) {
                reject(error)
                this.error({
                    error:error,
                    from:'ON_DOUBLE_CLICK',
                    page:NAME, 
                })  
            } 
        },
        ON_MOUSEOVER(){ 
            try {   
                window.addEventListener("mouseover", function(e) { 
                    const timeStamp = e.timeStamp;   
                    EventBus.$emit('ON_MOUSEOVER',e,timeStamp) 
                })
            } catch (error) {
                reject(error)
                this.error({
                    error:error,
                    from:'ON_MOUSEOVER',
                    page:NAME, 
                })  
            } 
        },

        START_NFC(nfc_link){ 
            try { 
                if (!nfc_link) {
                    nfc_link = DATA.APP.NFC_LINK
                }  
                this.CLOSE_NFC()
                this.NFC_LIVE = false
                this.NFC_STATUS = EventSource.CONNECTING

                this.NFC_EVENT = new EventSource(nfc_link)
                this.NFC_EVENT.onmessage = (event)=>{
                    this.NFC_LIVE = true
                    this.NFC_STATUS = EventSource.OPEN
                    const response = JSON.parse(event?.data)
                    const has = response?.HasDataResponse
                    const action = response?.Action
                    const response_data = response?.Data
                    const data = has?(response_data?.data):response_data
                    
                    if (action=="nfc") { 
                        EventBus.$emit('ON_NFC_MESSAGE',response)   
                    }
                    
                }
                this.NFC_EVENT.onerror = (event)=>{
                    this.NFC_LIVE = false
                    this.NFC_STATUS = EventSource.CLOSED
                    EventBus.$emit('ON_NFC_ERROR',event)  
                } 
                this.NFC_EVENT.onopen = (event)=>{
                    this.NFC_LIVE = true
                    this.NFC_STATUS = EventSource.OPEN
                    EventBus.$emit('ON_NFC_OPEN',event)  
                }  
            } catch (error) { 
                this.error({
                    error:error,
                    from:'START_NFC',
                    page:NAME, 
                })  
            } 
        },
        CLOSE_NFC(){ 
            try { 
                if (this.NFC_EVENT) {
                    this.NFC_EVENT.close()
                }
            } catch (error) {
                reject(error)
                this.error({
                    error:error,
                    from:'CLOSE_NFC',
                    page:NAME, 
                })  
            } 
        },
        START_LOCAL_DB(link){ 
            try { 
                if (!link) {
                    link = DATA.APP.LOCAL_DB_LINK
                }  
                this.CLOSE_LOCAL_DB()
                this.LOCAL_DB_LIVE = false
                this.LOCAL_DB_STATUS = EventSource.CONNECTING

                this.LOCAL_DB_EVENT = new EventSource(link)
                this.LOCAL_DB_EVENT.onmessage = (event)=>{
                    this.LOCAL_DB_LIVE = true
                    this.LOCAL_DB_STATUS = EventSource.OPEN
                    const response = JSON.parse(event?.data)
                    const has = response?.HasDataResponse
                    const action = response?.Action
                    const name = response?.Name
                    const request = response?.UserRequest
                    const response_data = response?.Data
                    const data = has?(response_data?.data):response_data

                    if (action!="nfc") { 
                        EventBus.$emit('ON_LOCAL_DB_MESSAGE',event)  
                    }
                    EventBus.$emit('ON_LAN',{event,response,has,action,name,request,response_data,data})   
                    console.log({event,response,has,action,name,request,response_data,data},'LAN.............+++'); 
                    store.dispatch('lan_update',{event,response,has,action,name,request,response_data,data})
                }
                this.LOCAL_DB_EVENT.onerror = (event)=>{
                    this.LOCAL_DB_LIVE = false
                    this.LOCAL_DB_STATUS = EventSource.CLOSED
                    EventBus.$emit('ON_LOCAL_DB_ERROR',event)  
                    
                    console.log(event,'ON_LOCAL_DB_ERROR.............+++'); 
                } 
                this.LOCAL_DB_EVENT.onopen = (event)=>{
                    this.LOCAL_DB_LIVE = true
                    this.LOCAL_DB_STATUS = EventSource.OPEN
                    EventBus.$emit('ON_LOCAL_DB_OPEN',event)  
                    console.log(event,'ON_LOCAL_DB_OPEN.............+++');
                    
                }  
            } catch (error) { 
                this.error({
                    error:error,
                    from:'START_LOCAL_DB',
                    page:NAME, 
                })  
            } 
        },
        CLOSE_LOCAL_DB(){ 
            try { 
                if (this.LOCAL_DB_EVENT) {
                    this.LOCAL_DB_EVENT.close()
                }
            } catch (error) {
                reject(error)
                this.error({
                    error:error,
                    from:'CLOSE_LOCAL_DB',
                    page:NAME, 
                })  
            } 
        },
    },
    watch:{
        LOCAL_DB_LIVE(value){
            store.dispatch('lan_status',value)
        }
    }
});
 